import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import InvalidLink from './pages/InvalidLink';
import EmbeddedWebforms from './pages/EmbeddedWebforms';
import LandingPage from './pages/LandingPage';
import WebformPage from './pages/Webform';

export default function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<WebformPage />}></Route>
        <Route path="/webforms" element={<WebformPage />}></Route>
        <Route path="/embed" element={<EmbeddedWebforms />}></Route>
        <Route path="/home" element={<LandingPage />}></Route>
        <Route path="/404" element={<InvalidLink />}></Route>
        <Route path="*" element={<InvalidLink />} />
      </Routes>
    </BrowserRouter>
  );
}
