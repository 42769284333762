import axios from 'axios';
// import config from 'Config';

const API_URL = process.env.REACT_APP_API_URL;

export async function getQuestionData(data) {
  return axios({
    method: 'POST',
    url: `${API_URL}/participant/question`,
    data,
  });
}

export async function fetchBusinessData(id) {
  return await axios.get(`${API_URL}/accounts/no-auth/${id}`);
}
