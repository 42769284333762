import React from 'react';
import './CSAT.sass';
// import PropTypes from 'prop-types';

/* Component for single select question types.
Takes in a question,
maps through the answer list and displays all possible answers */

const SingleSelect = ({ question, onChange, value }) => {
  function handleAnswer(event) {
    const index = event.target.value.split(',')[0];
    const text = event.target.value.split(',')[1];
    onChange({ value: index, text }, question.questionID, question.questionLevel);
  }

  // DO NOT change these values since we don't support other values for CSAT
  question.answerList = [
    { value: 'Very unsatisfied', text: 'Extremely Unsatisfied' },
    { value: 'Unsatisfied', text: 'Unsatisfied' },
    { value: 'Neutral', text: 'Neutral' },
    { value: 'Satisfied', text: 'Satisfied' },
    { value: 'Very satisfied', text: 'Extremely Satisfied' },
  ];

  return (
    <div className="csat-select">
      <h4 className="range-score-question">
        <p
          className="question-text"
          dangerouslySetInnerHTML={{ __html: question.questionText }}
        ></p>
      </h4>
      <div className="answer-list">
        {question &&
          question.answerList &&
          question.answerList.map((option, index) => (
            <div key={`${question.questionID}${index}`} className="radio-btn">
              <input
                type="radio"
                id={`${question.questionID}${index}`}
                value={[index + 1, option.value]}
                onChange={handleAnswer}
                checked={(index + 1).toString() === value}
              />
              <label className="answer" htmlFor={`${question.questionID}${index}`}>
                {index + 1 === 1 ? (
                  <p className="csat-emoji">😢</p>
                ) : index + 1 === 2 ? (
                  <p className="csat-emoji">🙁</p>
                ) : index + 1 === 3 ? (
                  <p className="csat-emoji">😐</p>
                ) : index + 1 === 4 ? (
                  <p className="csat-emoji">🙂</p>
                ) : (
                  <p className="csat-emoji">😃</p>
                )}
                <span className="text hide-on-mobile">{option.text}</span>
              </label>
            </div>
          ))}
      </div>
    </div>
  );
};

// SingleSelect.propTypes = {
//   onChange: PropTypes.func.isRequired,
//   value: PropTypes.string.isRequired,
//   question: PropTypes.objectOf(PropTypes.any).isRequired,
// };
export default SingleSelect;
