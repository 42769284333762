import React from 'react';
import './openEnded.sass';

const OpenEnded = ({ value, onChange, question }) => {
  let lowerLimit = 0;
  let upperLimit = 2047483647;
  if (question?.validationRule != null) {
    const validationValue = question.validationRule;
    const arr = validationValue.split('-');
    lowerLimit = parseInt(arr[0]);
    upperLimit = parseInt(arr[1]);
  }

  function handleComment(event) {
    let integerValue = event.target.value;
    onChange(
      { value: integerValue, text: integerValue },
      question.questionID,
      question.questionLevel,
    );
  }

  return (
    <div className="open-ended-container">
      <h4 className="range-score-question">
        <p className="question-text" dangerouslySetInnerHTML={{ __html: question.questionText }}>
          {/*{question?.required === true ? (*/}
          {/*  <span className="required-star">*</span>) : (<></>)}*/}
          {/*{' '}*/}
          {/*<strong>{question.questionText}</strong>*/}
        </p>
      </h4>
      {question.questionText !== 'You have already completed this survey.' && (
        <>
          <input
            type="number"
            min={lowerLimit}
            max={upperLimit}
            className="flat-text-area"
            value={value}
            placeholder="Type your answer here..."
            onChange={handleComment}
            name="comment"
            required
          />
          <small className="character-count">
            Enter number between {lowerLimit} - {upperLimit}.
          </small>
        </>
      )}
    </div>
  );
};

// OpenEnded.propTypes = {
//   onChange: PropTypes.func.isRequired,
//   value: PropTypes.string.isRequired,
//   question: PropTypes.objectOf(PropTypes.any).isRequired,
// };

export default OpenEnded;
