import React, { useRef } from 'react';
import './multipleSelect.sass';
// import PropTypes from 'prop-types';

/**
 * Component for multiple select question types.
 * Takes in a question, maps through the answer list and displays all possible answers
 * @param onChange
 * @param questionValue
 * @param question
 * @return {*}
 * @constructor
 */

const MultipleSelect = ({ onChange, questionValue, question }) => {
  const checkedInputCount = useRef(0);
  function handleCheckboxChange(event, maxSelections = undefined) {
    // Ensure that the user cannot select more checkboxes than the allowed maximum.
    if (maxSelections && event.target.checked) {
      if (checkedInputCount.current >= maxSelections) {
        checkedInputCount.current = maxSelections;
        return;
      }
      checkedInputCount.current++;
    } else if (maxSelections && !event.target.checked) {
      checkedInputCount.current--;
    }

    // End of checkbox selection limit logic.

    const index = event.target.value.split(',')[0];
    const text = event.target.value.split(',')[1];
    if (Array.isArray(questionValue.value)) {
      if (event.target.checked) {
        if (questionValue.value.includes(index)) {
          return;
        }
        onChange(
          {
            value: [...questionValue.value, index],
            text: [...questionValue.text, text],
          },
          question.questionID,
          question.questionLevel,
        );
        return;
      }
      onChange(
        {
          value: questionValue.value.filter((option) => option !== index),
          text: questionValue.text.filter((option) => option !== text),
        },
        question.questionID,
        question.questionLevel,
      );
      return;
    }

    if (event.target.checked) {
      onChange(
        {
          value: [...questionValue.value, index],
          text: [...questionValue.text, text],
        },
        question.questionID,
        question.questionLevel,
      );
    }
  }

  return (
    <div className="multiple-select">
      <h4 className="range-score-question">
        <p className="question-text" dangerouslySetInnerHTML={{ __html: question.questionText }}>
          {/*{question?.required === true ? (*/}
          {/*  <span className="required-star">*</span>) : (<></>)}*/}
          {/*<strong>{question.questionText}</strong>*/}
        </p>
      </h4>
      <div className="answer-list">
        {question &&
          question.answerList &&
          question.answerList.map((option, index) => {
            const regex = /\(Select up to (\d+) options\)/;
            const hasSelectionConstraint = regex.test(question.questionText);
            let numberOfSelections;
            if (hasSelectionConstraint) {
              const matchResult = regex.exec(question.questionText);
              if (matchResult) {
                numberOfSelections = Number(matchResult[1]);
              }
            }

            return (
              <div
                key={`${question.questionID}${index}`}
                className={`inputGroup
                    ${
                      numberOfSelections &&
                      checkedInputCount.current >= numberOfSelections &&
                      !questionValue.value.includes((index + 1).toString())
                        ? 'remove-border'
                        : ''
                    }
                  `}
              >
                <input
                  type="checkbox"
                  id={`${question.questionID}${index}`}
                  value={[index + 1, option]}
                  onChange={
                    !numberOfSelections
                      ? handleCheckboxChange
                      : (e) => handleCheckboxChange(e, numberOfSelections)
                  }
                  checked={questionValue.value.includes((index + 1).toString())}
                />
                <label
                  className={`answer
                    ${
                      numberOfSelections &&
                      checkedInputCount.current >= numberOfSelections &&
                      !questionValue.value.includes((index + 1).toString())
                        ? 'grayed-out'
                        : ''
                    }
                  `}
                  htmlFor={`${question.questionID}${index}`}
                >
                  <span style={{ display: 'inline-block', width: 'calc(100% - 45px)' }}>
                    {option}
                  </span>
                </label>
              </div>
            );
          })}
      </div>
    </div>
  );
};

// MultipleSelect.propTypes = {
//   onChange: PropTypes.func.isRequired,
//   questionValue: PropTypes.objectOf(PropTypes.any).isRequired,
//   question: PropTypes.objectOf(PropTypes.any).isRequired,
// };
export default MultipleSelect;
