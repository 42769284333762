/** this method will return the value of a nested object or null if any of parents don't exist
 * a proposal was done that was green-lit to be in ECMAScript 2020 where you can use ? to test
 * nested objects
 * for example response?.data?.school ,any failed parent will resolve safely
 * @param {object} object
 * @param {string} args
 * @return {*}
 */
export function getNestedObjectSafely(object, ...args) {
  return args.reduce((object, level) => object && object[level], object);
}

export function getQueryParameters(queryString, excludeKeys = ['j', 'm']) {
  const queryParameters = new URLSearchParams(queryString);
  const queryParams = {};

  for (const [key, value] of queryParameters) {
    if (!excludeKeys.includes(key)) {
      queryParams[key] = value;
    }
  }

  return queryParams;
}
