import React from 'react';
import './web-form.sass';

export default function InvalidLink() {
  return (
    <div style={{ display: 'grid', alignContent: 'center', height: '100vh' }}>
      <div className="form-submission-success">
        <div className="content-container">
          <div>
            <img className="logo-footer" src={require('./assets/ajua_logo.png')} alt="Ajua Logo" />
          </div>
          <br />
          <h3 className="title">
            <p style={{ fontWeight: 'bold', fontSize: '28px' }}>
              Sorry, the page you’re looking for cannot be found.
            </p>
            <p style={{ fontWeight: 'normal', fontSize: '18px' }}>
              This could mean that the survey is no longer active
              <br />
              or the link you followed was generated incorrectly.
            </p>
          </h3>
          <div>
            <a href="https://www.ajua.com">
              <div> Powered by Ajua</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
