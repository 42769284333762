import React, { useEffect } from 'react';
import InvalidLink from '../components/InvalidLink';
import Helmet from 'react-helmet';

export default function NotFoundPage() {
  useEffect(() => {
    if (document.referrer !== '') {
      window.parent.postMessage(
        { message: 'Invalid Link!', status: 404, ok: false, code: 'INVALID_LINK' },
        document.referrer,
      );
    }
  }, []);
  return (
    <>
      <Helmet>
        <title>Ajua Webforms | Survey Not Found</title>
      </Helmet>
      <InvalidLink />
    </>
  );
}
